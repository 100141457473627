import { SelectOption } from '@/models/form'
import { CONDITIONAL_OPTIONS_TYPE, ORDER_REQUIREMENTS__OPTION_TYPE } from './types'

export const ORDER_REQUIREMENTS: SelectOption<ORDER_REQUIREMENTS__OPTION_TYPE>[] = [
  {
    label: 'Good Till Canceled',
    value: ORDER_REQUIREMENTS__OPTION_TYPE.GOOD_TILL_CANCELED
  },
  {
    label: 'Fill or Kill',
    value: ORDER_REQUIREMENTS__OPTION_TYPE.FILL_OR_KILL
  },
  {
    label: 'Immediate Or Cancel',
    value: ORDER_REQUIREMENTS__OPTION_TYPE.IMMEDIATE_OR_CANCEL
  },
  {
    label: 'Post-Only',
    value: ORDER_REQUIREMENTS__OPTION_TYPE.POST_ONLY
  }
]

export const CONDITIONAL_OPTIONS: SelectOption<CONDITIONAL_OPTIONS_TYPE>[] = [
  {
    label: 'Bid',
    value: CONDITIONAL_OPTIONS_TYPE.BID
  },
  {
    label: 'Ask',
    value: CONDITIONAL_OPTIONS_TYPE.ASK
  },
  {
    label: 'Last',
    value: CONDITIONAL_OPTIONS_TYPE.LAST
  }
]
